import {ExtensibleEntityDto} from './ExtensibleEntityDto';
import {PagedAndSortedResultRequestDto} from './PagedAndSortedResultRequestDto';
import {Guid} from 'guid-typescript';

export interface ShipCardDto extends ExtensibleEntityDto<Guid> {
  cardType: number,
  price: number,
  second: number
}

export class CreateOrUpdateShipCardDto {
  public id: Guid = Guid.createEmpty();
  public cardType: number | undefined;
  public price: number | undefined;
  public second: number | undefined;
}

export class GetListShipCardDto extends PagedAndSortedResultRequestDto {
  public filter: string = '';
}
