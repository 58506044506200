import CrudAppService from '@/framework/CrudAppService';
import {ShipCardDto, CreateOrUpdateShipCardDto, GetListShipCardDto} from '@/framework/models/ShipCard';
import {Guid} from 'guid-typescript';


export default class ShipCardApi extends CrudAppService<ShipCardDto, ShipCardDto, Guid, GetListShipCardDto, CreateOrUpdateShipCardDto, CreateOrUpdateShipCardDto> {
  constructor() {
    super(`/keep-fit/ship-card`);
  }
}
