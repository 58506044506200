






















































import {Component, Vue} from 'vue-property-decorator';
import '@/plugins/vxetable';

import {PageResultDto} from '@/framework/models/ResultDto';
import {ShipCardDto, GetListShipCardDto} from '@/framework/models/ShipCard';
import ShipCardApi from '@/api/member/ship-card';

@Component({})
export default class ShipCard extends Vue {
  private api = new ShipCardApi();

  private listQuery = new GetListShipCardDto();
  private tableData: PageResultDto<ShipCardDto> = {
    items: new Array<ShipCardDto>(),
    totalCount: 0
  };

  private cards = ['次卡', '月卡', '季卡', '半年卡', '年卡', '两年卡', '家庭卡'];

  private rules = {
    cardType: [{
      required: true,
      message: '会员卡类型不能为空',
      trigger: 'blur'
    }],
    price: [{
      type: 'number',
      required: true,
      message: '请输入会员卡价格',
    }],
    second: [{
      type: 'number',
      min: 1,
      max: 1000,
      message: '请输入1-1000的数字',
    }],
  };

  private async activated() {
    await this.getList();
  }

  private getCardTypeName(type: number) {
    return this.cards.find((v: string, index: number) => {
      if (index === type - 1) {
        return v;
      }
    });
  }

  private async getList() {
    this.tableData = await this.api.getListAsync(this.listQuery);
  }

  private async creating(row: any) {
    await this.api.CreateAsync(row);
    await this.getList();
    this.$notify({
      message: '添加成功',
      type: 'success',
      title: ''
    });
  }

  private async editing(row: any) {
    await this.api.UpdateAsync(row.id, row);
    await this.getList();
    this.$notify({
      message: '编辑成功',
      type: 'success',
      title: ''
    });
  }


  private handleCreate() {
    this.insertEvent(null);
  }

  private updateHandle(row: any) {
    const $table = this.$refs.dataTable as any;
    $table.setActiveRow(row);
  }

  private cancelRowEvent(row: any) {
    const $table = this.$refs.dataTable as any;
    $table.clearActived().then(() => {
      // 还原行数据
      $table.revertData(row);
      // TODO 如果是新添加的行，需要删除该行
      if (!row.id) {
        $table.remove(row);
      }
    });
  }

  private async insertEvent(row: any) {
    const $table = this.$refs.dataTable as any;
    const insertRecords = $table.getInsertRecords();
    if (insertRecords.length <= 0) {
      const record = {};
      const {row: newRow} = await $table.insertAt(record, row);
      await $table.setActiveRow(newRow);
    }
  }

  private async saveEvent(row: any) {
    const $table = this.$refs.dataTable as any;
    const errMap = await $table.validate().catch((errMap: any) => errMap);
    if (!errMap) {
      if (row.cardType === 1 && !row.second) {
        this.$message({message: '请添加次卡次数', type: 'error'});
        return false;
      }
      $table.clearActived().then(() => {
        if ($table.isUpdateByRow(row)) {
          this.editing(row);
        } else if (!row.id) {
          this.creating(row);
        }
      });
    }
  }

  private handlerDelete(row: any) {
    this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'error'
    }).then(async () => {
      // TODO 代码
      const $table = this.$refs.dataTable as any;
      await $table.remove(row);
      if (row.id) {
        await this.api.DeleteAsync(row.id);
        await this.getList();
        this.$notify({
          message: '删除成功',
          type: 'success',
          title: ''
        });
      }
    }).catch(() => {
    });
  }
};
